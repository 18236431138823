class ApplicationService {
  get_applications(page, data) {

    data.append('type', 'fieldvisit');
    return axios.post('general/fieldform?page=' + page, data)
  }
  edit_application(id) {
    return axios.get('general/fieldform/edit/' + id)
  }
  get_application(data) {
    return axios.post('general/fieldform/create', data)
  }
  create_application(data) {
    return axios.post('general/fieldform/store', data)
  }
  add_application(data) {
    return axios.post('general/fieldform/store', data)
  }
  answer_application(data) {
    return axios.post('general/fieldform/add-one-service-answers', data)
  }
  add_attachment(data) {
    return axios.post('general/fieldform/add-attachment', data)
  }
  get_assign_camps_with_sqaure() {
    return axios.get('general/fieldform/camp/has-order/has-added-license/unready-license?type=fieldvisit')
  }

  delete_service(fieldform_id, service_id) {
    return axios.delete('general/fieldform/delete-service-answer/' + fieldform_id + '/' + service_id)
  }

  store_persentage_specialistis(data) {
    return axios.post('general/fieldform/update-specialist-percentage', data)
  }

  details_application(id) {
    return axios.get('general/fieldform/get-details/' + id)
  }
  update_application(id, data) {
    return axios.post('general/fieldform/update/' + id, data)
  }
  // destroy_application(id) {
  //   return axios.delete('general/fieldform/delete/' + id)
  // }
}
export default new ApplicationService();