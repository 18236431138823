import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "ReportsApps",
    children: [
        {
            path: "/reports-apps/create",
            name: "NewReportApp",
            component: () => import("@/modules/report_app/views/Applications/New.vue"),
            meta: {
                groupName: "ReportApplicationFielForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "report-app-create",
            },
        },
        {
            path: "/reports-apps/edit/:id",
            name: "EditReportApp",
            component: () => import("@/modules/report_app/views/Applications/Edit.vue"),
            meta: {
                groupName: "ReportApplicationFielForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "report-app-update",
            },
        },

        {
            path: "/reports-apps",
            name: "ReportApplicationFielForm",
            component: () => import("@/modules/report_app/views/Applications/Show.vue"),
            meta: {
                groupName: "ReportApplicationFielForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "application-report-app-index",
            },
        },

        {
            path: "/reports-apps/details/:id",
            name: "ReportApplicationFielFormDetails",
            component: () => import("@/modules/report_app/views/Applications/Details.vue"),
            meta: {
                groupName: "ReportApplicationFielForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "report-app-details",
            },
        },



    ],

}