import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "Applications",
    children: [
        {
            path: "/applications/create",
            name: "NewApplication",
            component: () => import("@/modules/applications/views/Applications/New.vue"),
            meta: {
                groupName: "ApplicationFielForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "application-create",
            },
        },
        {
            path: "/applications/edit/:id",
            name: "EditApplication",
            component: () => import("@/modules/applications/views/Applications/Edit.vue"),
            meta: {
                groupName: "ApplicationFielForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "application-update",
            },
        },

        {
            path: "/applications",
            name: "ApplicationFielForm",
            component: () => import("@/modules/applications/views/Applications/Show.vue"),
            meta: {
                groupName: "ApplicationFielForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "application-index",
            },
        },

        {
            path: "/applications/details/:id",
            name: "ApplicationDetailPage",
            component: () => import("@/modules/applications/views/Applications/Details.vue"),
            meta: {
                groupName: "ApplicationFielForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "application-index",
            },
        },



    ],

}